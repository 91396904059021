<template>
  <div class="protocol-wrapper">
    <el-row>
      <el-col :xs="20" :sm="20" :md="18" :lg="18" :xl="18">
        <h3 id="title">{{ $t("L_PROTOCOL.TITLE") }}</h3>
        <p class="p1">
          {{ $t("L_PROTOCOL.CONTENT_0001") }}
        </p>
        <p class="p1">
          {{ $t("L_PROTOCOL.CONTENT_0002") }}
        </p>
        <h4 class="h4">{{ $t("L_PROTOCOL.CONTENT_0003") }}</h4>
        <p class="p2">{{ $t("L_PROTOCOL.CONTENT_0004") }}</p>
        <ul id="ul">
          <li>{{ $t("L_PROTOCOL.CONTENT_0005") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0006") }}</li>
        </ul>
        <h4 class="h4">{{ $t("L_PROTOCOL.CONTENT_0007") }}</h4>
        <p class="p2">{{ $t("L_PROTOCOL.CONTENT_0008") }}</p>
        <ul>
          <li>{{ $t("L_PROTOCOL.CONTENT_0009") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0010") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0011") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0012") }}</li>
        </ul>
        <h4 class="h4">{{ $t("L_PROTOCOL.CONTENT_0013") }}</h4>
        <p class="p1">{{ $t("L_PROTOCOL.CONTENT_0014") }}</p>
        <p class="p1">{{ $t("L_PROTOCOL.CONTENT_0015") }}</p>
        <p class="p1">{{ $t("L_PROTOCOL.CONTENT_0016") }}</p>
        <p class="p1">{{ $t("L_PROTOCOL.CONTENT_0017") }}</p>
        <h4 class="h4">{{ $t("L_PROTOCOL.CONTENT_0018") }}</h4>
        <p class="p1">{{ $t("L_PROTOCOL.CONTENT_0019") }}</p>
        <p class="p1">{{ $t("L_PROTOCOL.CONTENT_0020") }}</p>
        <p class="p1">{{ $t("L_PROTOCOL.CONTENT_0021") }}</p>
        <h4 class="h4">{{ $t("L_PROTOCOL.CONTENT_0022") }}</h4>
        <p class="p1">{{ $t("L_PROTOCOL.CONTENT_0023") }}</p>
        <p class="p2">{{ $t("L_PROTOCOL.CONTENT_0024") }}</p>
        <ul>
          <li>{{ $t("L_PROTOCOL.CONTENT_0025") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0026") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0027") }}</li>
        </ul>
        <p class="p2">{{ $t("L_PROTOCOL.CONTENT_0028") }}</p>
        <ul>
          <li>{{ $t("L_PROTOCOL.CONTENT_0029") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0030") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0031") }}</li>
        </ul>
        <p class="p2">{{ $t("L_PROTOCOL.CONTENT_0032") }}</p>
        <ul>
          <li>{{ $t("L_PROTOCOL.CONTENT_0033") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0034") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0035") }}</li>
        </ul>
        <p class="p2">{{ $t("L_PROTOCOL.CONTENT_0036") }}</p>
        <p class="p2">{{ $t("L_PROTOCOL.CONTENT_0037") }}</p>
        <ul>
          <li>{{ $t("L_PROTOCOL.CONTENT_0038") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0039") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0040") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0041") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0042") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0043") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0044") }}</li>
        </ul>
        <p class="p2">{{ $t("L_PROTOCOL.CONTENT_0045") }}</p>
        <p class="p2">{{ $t("L_PROTOCOL.CONTENT_0046") }}</p>
        <p class="p2">{{ $t("L_PROTOCOL.CONTENT_0047") }}</p>
        <ul>
          <li>{{ $t("L_PROTOCOL.CONTENT_0048") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0049") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0050") }}</li>
        </ul>
        <p class="p2">{{ $t("L_PROTOCOL.CONTENT_0051") }}</p>
        <ul>
          <li>{{ $t("L_PROTOCOL.CONTENT_0052") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0053") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0054") }}</li>
        </ul>
        <h4 class="h4">{{ $t("L_PROTOCOL.CONTENT_0055") }}</h4>
        <p class="p1">{{ $t("L_PROTOCOL.CONTENT_0056") }}</p>
        <p class="p1">{{ $t("L_PROTOCOL.CONTENT_0057") }}</p>
        <p class="p2">{{ $t("L_PROTOCOL.CONTENT_0058") }}</p>
        <p class="p2">{{ $t("L_PROTOCOL.CONTENT_0059") }}</p>
        <ul>
          <li>{{ $t("L_PROTOCOL.CONTENT_0060") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0061") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0062") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0063") }}</li>
          <li>{{ $t("L_PROTOCOL.CONTENT_0064") }}</li>
        </ul>
        <h4 class="h4">{{ $t("L_PROTOCOL.CONTENT_0065") }}</h4>
        <p class="p2" style="margin-bottom: 80px">
          {{ $t("L_PROTOCOL.CONTENT_0066") }}
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "protocol",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/protocol";
</style>
